import React from "react";
import "./banner.css";

export default function Banner() {
  return (
    <div className="banner">
      <h1>" Helping Everyone Find Their Place in the World. "</h1>
      <p className="small-header">Lila Delman</p>
    </div>
  );
}
