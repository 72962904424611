export const sliderData = [
  {
    id: "001",
    img: require("../src/Media/NOIR67.jpg"),
    title: "Welcome to OneWorld Luxury",
    subtitle1: "Helping Everyone Find Their Place in the World. ",
    subtitle2: "",
    cta: "BOOK NOW",
  },
  {
    id: "002",
    img: require("../src/Media/NOIR11.jpg"),
    title: "Welcome to OneWorld Luxury",
    subtitle1: "Elevate your lifestyle to new heights of prestige and comfort",
    subtitle2: "in our exclusive high-class luxury apartments.",
    cta: "BOOK NOW",
  },
  {
    id: "003",
    img: require("../src/Media/NOIR9.jpg"),
    title: "Welcome to OneWorld Luxury",
    subtitle1: "Indulge in the epitome of luxury living where opulence  ",
    subtitle2: "meets unparalleled comfort in every corner.",
    cta: "BOOK NOW",
  },
  {
    id: "004",
    img: require("../src/Media/NOIR23.jpg"),
    title: "Welcome to OneWorld Luxury",
    subtitle1: "Experience the pinnacle of sophistication and comfort in  ",
    subtitle2: "our prestigious high-class luxury residences.",
    cta: "BOOK NOW",
  },
];

export const blog = [
  {
    id: 1,
    title:
      "Unveiling One World Luxury Apartments: A Triple Crown Winner in Opulence",
    desc1:
      "In the realm of luxury accommodations, One World Luxury Apartments stands tall as a beacon of sophistication and comfort, boasting an impressive track record as a three-time award-winning masterpiece. Step into a world where opulence knows no bounds, and every facet of your stay is meticulously curated to surpass even the loftiest expectations.",
    desc2:
      "1. A Symphony of Elegance: One World Luxury Apartments invites you into a realm of refined elegance and unparalleled design. The interiors, adorned with exquisite furnishings and tasteful decor, create a harmonious symphony of luxury. From the moment you enter, a sense of opulence permeates the air, setting the stage for an indulgent experience that defines the pinnacle of high-end living.",
    desc3:
      "2. Cutting-Edge Amenities: This award-winning gem isn't just about lavish interiors; it's a haven of cutting-edge amenities that redefine the meaning of luxury living. State-of-the-art appliances, smart home technology, and wellness-focused features transform each apartment into a sanctuary of modern convenience. Whether you're unwinding in the spa-like bathrooms or preparing a gourmet meal in the chef-inspired kitchens, every detail is designed to elevate your stay to extraordinary heights.",
    desc4:
      "3. Panoramic Views and Prime Location: Perched in a prime location, One World Luxury Apartments offer more than just opulent interiors. Floor-to-ceiling windows showcase breathtaking panoramic views of the surrounding cityscape, creating a backdrop that seamlessly merges urban sophistication with natural beauty. The central location ensures that you are at the heart of the action, with easy access to the city's finest dining, entertainment, and cultural offerings.",
    desc5:
      "4. Award-Winning Excellence: What sets One World Luxury Apartments apart from the rest is its status as a three-time award winner. Recognized for excellence in design, hospitality, and innovation, these accolades are a testament to the commitment to providing an unparalleled guest experience. The awards serve as a mark of distinction, assuring guests that they are not merely booking a stay but embarking on a journey into a world of luxury that has been celebrated by industry experts.",
    desc6:
      "Conclusion: One World Luxury Apartments isn't just a place to stay; it's a destination where every moment is crafted to be an experience worth cherishing. As a three-time award-winning haven of opulence, this residence redefines luxury living, setting a standard that few can match. When seeking the epitome of sophistication and comfort, One World Luxury Apartments stands as a beacon, beckoning you to immerse yourself in the pinnacle of refined living.",
    author: "RACHEL BENTIL",
    cover: require("./Media/NOIR20.jpg"),
    date: "JANUARY 09, 2024",
  },
  {
    id: 2,
    title: "Indulgence Redefined: The One World Luxury Apartment",
    desc1:
      "Discover the epitome of opulence at the One World Luxury Apartment, a residence that transcends ordinary living with its unmatched sophistication and world-class features. Renowned as a three-time award-winning masterpiece, this exceptional dwelling redefines luxury living in every conceivable way.",
    desc2:
      "From the moment you step into the meticulously designed foyer, you're greeted by an ambiance of pure extravagance. The interiors boast a seamless blend of modern elegance and timeless aesthetics, with high-end furnishings and state-of-the-art appliances that elevate the living experience to new heights. Every corner of the apartment reflects a commitment to unparalleled luxury, creating an environment that exudes both style and comfort.",
    desc3:
      "The panoramic views from the expansive windows of the One World Luxury Apartment are nothing short of breathtaking. Perched high above the cityscape, residents are treated to an ever-changing, mesmerizing panorama that includes iconic landmarks and stunning skylines. This unique vantage point transforms each moment spent within the apartment into a visual feast, capturing the essence of luxury living.",
    desc4:
      "One of the standout features of this award-winning residence is its commitment to offering an all-encompassing experience. The amenities provided are a testament to this commitment, ranging from a fully equipped fitness center and spa to a rooftop infinity pool that provides an oasis of relaxation with unparalleled views. Every detail has been carefully considered to ensure that residents have access to the finest facilities, promoting a lifestyle of utmost comfort and indulgence.",
    desc5:
      "What sets the One World Luxury Apartment apart is not just its physical grandeur but also its recognition as a three-time award-winning haven. These prestigious accolades affirm its status as a pinnacle of luxury living, celebrated for its design excellence, outstanding amenities, and unparalleled service. Choosing to reside in this award-winning masterpiece isn't just a decision; it's a commitment to a lifestyle marked by uncompromising luxury, sophistication, and an unrivaled level of prestige. The One World Luxury Apartment is more than a residence; it's an experience that exceeds expectations and sets a new standard for luxurious living.",
    desc6: "",
    author: "LARA JACOBS",
    cover: require("./Media/NOIR12.jpg"),
    date: "JANUARY 07, 2024",
  },
  {
    id: 3,
    title:
      "Unveiling the Pinnacle of Opulence: One World Luxury Apartment – A Triple Crown Winner",
    desc1:
      "In the realm of luxury living, the One World Luxury Apartment stands as an undisputed gem, a testament to opulence and refinement. This illustrious residence, a three-time award-winning marvel, captivates discerning individuals seeking the epitome of sophistication. Boasting an array of extraordinary features, it offers an unparalleled experience for those who revel in the finer things in life.",
    desc2:
      "At the heart of the One World Luxury Apartment's allure is its exquisite design. From the moment you step inside, you are enveloped in an atmosphere of elegance. The meticulously curated interiors showcase a seamless blend of modern aesthetics and timeless luxury. High-end furnishings, bespoke finishes, and state-of-the-art appliances contribute to an ambiance that exudes both comfort and grandeur.",
    desc3:
      "One of the standout features that sets this apartment apart is its panoramic views. Perched in a prime location, residents are treated to breathtaking vistas that span the city skyline. Whether enjoying a morning coffee or unwinding in the evening, the expansive windows provide a constant connection to the dynamic energy of the surrounding urban landscape. It's a visual feast that complements the lavish interiors, creating a harmonious retreat for the privileged few.",
    desc4:
      "Luxury isn't just about aesthetics; it's about the seamless integration of convenience and indulgence. The One World Luxury Apartment excels in this regard with its unparalleled amenities. From a fully equipped gourmet kitchen to spa-like bathrooms and cutting-edge smart home technology, every facet of this residence has been designed with the utmost attention to detail. Residents can relish in the comfort of knowing that every need is not only met but exceeded.",
    desc5:
      "What truly elevates the One World Luxury Apartment to a league of its own is its prestigious standing as a three-time award winner. Recognized for excellence in design, innovation, and overall luxury, these accolades underscore the commitment to perfection that defines this residence. It is a testament to the dedication of those behind the creation of this masterpiece, who have meticulously crafted an environment that exceeds even the loftiest expectations.",
    desc6:
      "In conclusion, the One World Luxury Apartment stands as a pinnacle of opulence, a haven where luxury is not just a lifestyle but an art form. Its extraordinary design, panoramic views, unparalleled amenities, and prestigious accolades make it a residence that transcends the ordinary. For those fortunate enough to call it home, the One World Luxury Apartment is more than an address; it's a statement of unparalleled luxury living.",
    author: "ANDREW GOODWIN",
    cover: require("./Media/NOIR4.jpg"),
    date: "JANUARY 05, 2024",
  },
  {
    id: 4,
    title:
      "A Mother's Oasis: The One World Luxury Apartment – A Triple Crown Winner",
    desc1:
      "As a mother of three, navigating the demands of family life often feels like a whirlwind. However, our recent stay at the One World Luxury Apartment provided a much-needed respite, transcending the ordinary and delivering an experience that felt like a dream come true. This triple crown-winning marvel not only met but exceeded our expectations, offering a sanctuary of sophistication and ease.",
    desc2:
      "From the moment we stepped into the One World Luxury Apartment, it was evident that this was no ordinary residence. The meticulously curated interiors struck the perfect balance between modern aesthetics and family-friendly comfort. The high-end furnishings weren't just stylish; they were also durable, making our stay with three active children stress-free and enjoyable. It was a haven where every member of the family could find their own space to relax and recharge.",
    desc3:
      "The panoramic views from our apartment were nothing short of breathtaking. Watching the city come alive from the comfort of our living room was a daily highlight. The expansive windows not only brought in an abundance of natural light but also provided a captivating backdrop that added a touch of magic to our family moments. It was a joy to witness the excitement in my children's eyes as they marveled at the city skyline.",
    desc4:
      "What truly set the One World Luxury Apartment apart for us were the thoughtful amenities that catered to both adults and children alike. The fully equipped gourmet kitchen made preparing family meals a pleasure, and the spa-like bathrooms provided a touch of indulgence. Smart home technology simplified our stay, allowing us to effortlessly control the ambiance and entertainment, making it a home away from home.",
    desc5:
      "As a mother, I appreciated the attention to detail that went into creating an environment where our family could unwind and create lasting memories. The fact that this apartment has been honored three times with prestigious awards is a testament to the commitment to excellence that resonates throughout every inch of the space. It was more than a luxurious retreat; it was a haven where the needs of families were not just considered but celebrated.",
    desc6:
      "In conclusion, our stay at the One World Luxury Apartment was a transformative experience for our family. It offered a unique blend of luxury and functionality, creating an oasis that allowed us to connect, recharge, and savor the moments that matter most. For any family seeking a truly exceptional getaway, the One World Luxury Apartment is not just an option; it's a revelation in luxury living that caters to the heart of what matters most – family.",
    author: "PRINCE DARKO",
    cover: require("./Media/NOIR68.jpg"),
    date: "DECEMBER 30, 2023",
  },
  {
    id: 5,
    title:
      "Unveiling Luxury: One World Retreat – An Exclusive Hideaway for the Discerning",
    desc1:
      "As a discerning client with a penchant for the finer things in life, my recent sojourn at the One World Luxury Apartment was nothing short of a revelation. This three-time award-winning haven, tucked away in the heart of opulence, offered an unrivaled experience in privacy and sophistication. From the discreet entrance to the lavish interiors, every facet of the One World Retreat catered to my desire for an exclusive escape.",
    desc2:
      "From the moment I arrived, the discreet ambiance of the One World Luxury Apartment set the tone for a truly private retreat. The meticulous attention to detail in design and layout ensured that every corner of the residence exuded an air of exclusivity. It was a sanctuary where I could revel in solitude while surrounded by the epitome of luxury. The privacy afforded by the apartment's strategic design made it a haven for those who value seclusion.",
    desc3:
      "The panoramic views from my private enclave were nothing short of breathtaking. The expansive windows offered a front-row seat to the city's splendor, providing a mesmerizing backdrop to my moments of contemplation. The feeling of being cocooned in luxury, with the world unfolding beyond those glass walls, added an extra layer of exclusivity to my stay.",
    desc4:
      "The amenities within the One World Luxury Apartment were not just luxurious; they were tailored to fulfill my every need. The fully equipped kitchen allowed for discreet dining, and the state-of-the-art entertainment system provided the perfect escape within the confines of my private sanctuary. The seamless integration of technology and comfort further elevated the sense of exclusivity, making it clear that every detail had been considered with my privacy in mind.",
    desc5:
      "Being a three-time award-winning residence, the accolades were not just a testament to its aesthetic appeal but also an assurance of an unparalleled level of service. The discreet yet attentive staff ensured that my privacy was never compromised, and my requests were met with efficiency and discretion. It was a level of service that reflected the commitment to excellence that the One World Luxury Apartment is renowned for.",
    desc6:
      "In conclusion, my stay at the One World Luxury Apartment was an immersion into a world where privacy and luxury converged seamlessly. It was more than a residence; it was an exclusive hideaway designed for those who appreciate the finer nuances of privacy and sophistication. For the discerning individual seeking an escape from the ordinary, the One World Retreat stands as a testament to the art of indulgence in seclusion.",
    author: "MILLICENT ADOMAA",
    cover: require("./Media/NOIR6.jpg"),
    date: "DECEMBER 26, 2023",
  },
];

export const imgDocs = [
  {
    id: 44,
    url: require("./Media/NOIR25.jpg"),
  },
  {
    id: 12,
    url: require("./Media/NOIR26.jpg"),
  },
  {
    id: 2,
    url: require("./Media/NOIR68.jpg"),
  },
  {
    id: 3,
    url: require("./Media/NOIR67.jpg"),
  },
  {
    id: 36,
    url: require("./Media/NOIR5.jpg"),
  },
  {
    id: 37,
    url: require("./Media/NOIR6.jpg"),
  },
  {
    id: 38,
    url: require("./Media/NOIR8.jpg"),
  },
  {
    id: 39,
    url: require("./Media/NOIR9.jpg"),
  },
  {
    id: 31,
    url: require("./Media/NOIR10.jpg"),
  },
  {
    id: 23,
    url: require("./Media/NOIR21.jpg"),
  },
  {
    id: 25,
    url: require("./Media/NOIR18.jpg"),
  },
  {
    id: 40,
    url: require("./Media/NOIR14.jpg"),
  },
  {
    id: 28,
    url: require("./Media/NOIR15.jpg"),
  },
  {
    id: 29,
    url: require("./Media/NOIR12.jpg"),
  },
  {
    id: 30,
    url: require("./Media/NOIR11.jpg"),
  },
  {
    id: 50,
    url: require("./Media/NOIR59.jpg"),
  },
  {
    id: 52,
    url: require("./Media/NOIR63.jpg"),
  },
  {
    id: 1,
    url: require("./Media/NOIR62.jpg"),
  },
  {
    id: 4,
    url: require("./Media/NOIR58.jpg"),
  },
  {
    id: 13,
    url: require("./Media/NOIR54.jpg"),
  },
  {
    id: 14,
    url: require("./Media/NOIR53.jpg"),
  },
  {
    id: 15,
    url: require("./Media/NOIR52.jpg"),
  },
  {
    id: 16,
    url: require("./Media/NOIR51.jpg"),
  },
  {
    id: 19,
    url: require("./Media/NOIR43.jpg"),
  },
  {
    id: 20,
    url: require("./Media/NOIR41.jpg"),
  },
  {
    id: 33,
    url: require("./Media/NOIR4.jpg"),
  },
  {
    id: 35,
    url: require("./Media/NOIR2.jpg"),
  },
  {
    id: 45,
    url: require("./Media/NOIR30.jpg"),
  },
  {
    id: 46,
    url: require("./Media/NOIR34.jpg"),
  },
  {
    id: 7,
    url: require("./Media/NOIR38.jpg"),
  },
  {
    id: 8,
    url: require("./Media/NOIR37.jpg"),
  },
  {
    id: 11,
    url: require("./Media/NOIR27.jpg"),
  },
];

export const imgMasters = [
  {
    id: 1,
    url: require("./Media/NOIR41.jpg"),
  },
  {
    id: 3,
    url: require("./Media/NOIR43.jpg"),
  },
  {
    id: 11,
    url: require("./Media/NOIR51.jpg"),
  },
  {
    id: 12,
    url: require("./Media/NOIR34.jpg"),
  },
  {
    id: 15,
    url: require("./Media/NOIR37.jpg"),
  },
  {
    id: 16,
    url: require("./Media/NOIR38.jpg"),
  },
];

export const imgJuniors = [
  {
    id: 1,
    url: require("./Media/NOIR59.jpg"),
  },
  {
    id: 4,
    url: require("./Media/NOIR62.jpg"),
  },
  {
    id: 6,
    url: require("./Media/NOIR2.jpg"),
  },
];

export const imgStandards = [
  {
    id: 1,
    url: require("./Media/NOIR53.jpg"),
  },
  {
    id: 2,
    url: require("./Media/NOIR54.jpg"),
  },
  {
    id: 6,
    url: require("./Media/NOIR58.jpg"),
  },
  {
    id: 7,
    url: require("./Media/NOIR27.jpg"),
  },
  {
    id: 10,
    url: require("./Media/NOIR30.jpg"),
  },
  {
    id: 11,
    url: require("./Media/NOIR33.jpg"),
  },
];
