import React from "react";
import { useRef } from "react";
import {
  BsInstagram,
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";
import IGpic1 from "../../Media/NOIR8.jpg";
import IGpic2 from "../../Media/NOIR11.jpg";
import IGpic3 from "../../Media/NOIR12.jpg";
import IGpic4 from "../../Media/NOIR13.jpg";
import IGpic5 from "../../Media/NOIR16.jpg";
import "./iggallery.css";

const galleryImages = [IGpic1, IGpic2, IGpic3, IGpic4, IGpic5];

export default function IGgallery() {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="ig-gallery">
      <div className="ig-gallery-content">
        <h6 className="small-header">INSTAGRAM</h6>
        <h1 className="header">
          Photo <span style={{ color: "#B48D52" }}>Gallery</span>
        </h1>
        <p style={{ color: "grey", marginRight: "20px" }}>
          Follow Our Instagram and Check out our posts and updates. 
        </p>
        <a href="https://www.instagram.com/oneworldluxurygh/?igsh=OGQ5ZDc2ODk2ZA%3D%3D">
        <button type="button">VIEW MORE</button>
        </a>
      </div>

      <div className="ig-gallery-images">
        <div className="ig-gallery-images_container" ref={scrollRef}>
          {galleryImages.map((image, index) => (
            <div
              className="ig-gallery-images_card"
              key={`gallery_image-${index + 1}`}
            >
              <img src={image} alt="gallery" />
              <BsInstagram className="gallery__image-icon" />
            </div>
          ))}
        </div>
        <div className="ig-gallery-images_arrows">
          <BsArrowLeftShort
            className="gallery__arrow-icon"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className="gallery__arrow-icon"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
}
