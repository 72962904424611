import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { MdOutlineMailOutline } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import HeaderBanner from "../../Components/HeaderBanner/HeaderBanner";
import formImg from "../../Media/NOIR8.jpg";
import "./contactpage.css";

export default function ContactPage() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gnvk89i",
        "template_dn8q74f",
        form.current,
        "U0O5W5aJMkwfB_YAh"
      )
      .then(
        (result) => {
          alert("Message has been sent successfully");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <HeaderBanner header="Contact Us" />
      <div className="contactpage">
        <div className="contact-boxes">
          <div className="contact-box">
            <MdOutlineMailOutline />
            <h3>Email Address</h3>
            <a href="mailto:oneworldluxury1@gmail.com">
              <h6>oneworldluxury1@gmail.com</h6>
            </a>
          </div>
          <div className="contact-box">
            <LiaPhoneVolumeSolid />
            <h3>Phone Number</h3>
            <a href="tel:233531100850">
              <h6>+233 531 100 850</h6>
            </a>
          </div>
          <div className="contact-box">
            <SlLocationPin />
            <h3>Official Address</h3>
            <a href="https://maps.app.goo.gl/b9BVyCPpoJzisqpS8?g_st=iw">
              <h6>Palm Court, Airport Residential Area</h6>
            </a>
          </div>
        </div>
        <div className="contact-form" id="contact-form">
          <div className="form-img">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.7508010619918!2d-0.1799208!3d5.6037826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9b028090af87%3A0xa0d393c3cd37af23!2sPalm%20Court%20Apartments%20Ghana!5e0!3m2!1sen!2sgh!4v1706389043100!5m2!1sen!2sgh"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="form">
            <h6 className="small-header">MAKE APPOINTMENT</h6>
            <h1 className="header">Get In Touch</h1>
            <form ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                name="user_name"
                placeholder="Enter Your Name"
                required
              />
              <br />
              <input
                type="email"
                name="user_email"
                placeholder="Enter Email Address"
                required
              />
              <br />
              <textarea
                rows={4}
                name="message"
                placeholder="Message"
                required
              />
              <br />
              <button type="submit">SEND MESSAGE</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
