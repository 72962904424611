import React from "react";
import "./award.css";
import Award1 from "../../Media/077.jpeg";
import Award2 from '../../Media/nnn.jpeg';

export default function Award() {
  return (
    <div className="awards">
      <h6 className="small-header">AWARDS AND RECOGNITION</h6>
      <h1 className="header">
        Explore Our <span style={{ color: "#B48D52" }}>AWARDS</span>
      </h1>
      <div className="award-containers">
          <div className="award-container">
            <img src={Award1} alt="Award"/>
            <p>Best Luxury Residential Interior Design for the Palm Court Project Ghana by the LUXURY LIFESTYLE AWARDS 2022 U.S New York</p>
          </div>
          <div className="award-container">
            <img src={Award2} alt="Award"/>
            <p>Best Residential Interior Apartment Ghana for the Palm Court Project. INTERNATIONAL PROPERTY AWARDS- AFRICA PROPERTY AWARDS 2023- 2024 U. K Chelmsford</p>
          </div>
      </div>
    </div>
  );
}
