import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { blog } from "../../sliderData";
import HeaderBanner from "../../Components/HeaderBanner/HeaderBanner";
import "./blogpage.css";

export default function BlogPage() {
  return (
    <>
      <HeaderBanner header="Blogs" />
      <div className="blogpage">
        <h6 className="small-header">OUR BLOGS</h6>
        <h1 className="header">Read Our Stories</h1>
        <div className="blog-boxes">
          {blog.map((item) => (
            <div className="blog-box" key={item.id}>
              <img src={item.cover} alt="blog" />
              <div className="blogbox-content">
                <Link to={`/details/${item.id}`} className="link">
                  <h3>{item.title}</h3>
                </Link>
                <p>{item.desc1.slice(0, 180)}... </p>
                <div className="blog-date">
                  <p>
                    <AiOutlineClockCircle color="black" />
                    {"  "}
                    {item.date}
                  </p>
                  <p>{item.author}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
