import React from "react";
import ImageSlider from "../../Components/ImageSlider/ImageSlider";
import { sliderData } from "../../sliderData";
import "../../App.css";
import About from "../../Components/About/About";
import Apartments from "../../Components/Apartments/Apartments";
import WhyUs from "../../Components/WhyUs/WhyUs";
import Banner from "../../Components/Banner/Banner";
import Video from "../../Components/Video/Video";
import Testimonials from "../../Components/Testimonials/Testimonials";
import IGgallery from "../../Components/IGgallery/IGgallery";
import Facilities from "../../Components/Facilities/Facilities";
import Availability from "../../Components/Availability/Availability";
import Map from "../../Components/Map/Map";
import Nearby from "../../Components/Nearby/Nearby";

export default function HomePage() {
  return (
    <div className="App">
      <ImageSlider slides={sliderData} />
      <Availability />
      <About />
      <Banner />
      <Apartments />
      <Facilities />
      <IGgallery />
      <WhyUs />
      <Video />
      <Testimonials />
      <Nearby />
      <Map />
    </div>
  );
}
