import React from "react";
import { PiCarProfileLight } from "react-icons/pi";
import { IoWifiOutline } from "react-icons/io5";
import { PiSwimmingPoolThin } from "react-icons/pi";
import { MdOutlineSecurity } from "react-icons/md";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { BsPersonWorkspace } from "react-icons/bs";
import "./facilities.css";

export default function Facilities() {
  return (
    <div className="facilities">
      <h6 className="small-header">FACILITIES AND AMENITIES</h6>
      <h1 className="header">
        Main <span style={{ color: "#B48D52" }}>Amenities</span>
      </h1>
      <div className="facilities-container">
        <div className="facility">
          <div className="circle">
            <MdOutlineSecurity />
          </div>
          <h3>24/7 Security</h3>
        </div>
        <div className="facility">
          <div className="circle">
            <IoWifiOutline />
          </div>
          <h3>Wifi & Internet</h3>
        </div>
        <div className="facility">
          <div className="circle">
            <BsPersonWorkspace />
          </div>
          <h3>WFH Space</h3>
        </div>
      </div>
      <div className="facilities-container">
        <div className="facility">
          <div className="circle">
            <PiCarProfileLight />
          </div>
          <h3>Private Parking</h3>
        </div>
        <div className="facility">
          <div className="circle">
            <MdOutlineLocalLaundryService />
          </div>
          <h3>In Unit Laundry</h3>
        </div>
        <div className="facility">
          <div className="circle">
            <PiSwimmingPoolThin />
          </div>
          <h3>Swimming Pool</h3>
        </div>
      </div>
    </div>
  );
}
