import React from "react";
import HeaderBanner from "../../Components/HeaderBanner/HeaderBanner";
import AboutImg1 from "../../Media/NOIR25.jpg";
import AboutImg2 from "../../Media/NOIR52.jpg";
import "./aboutpage.css";
import "../../Components/About/about.css";
import Leaders from "../../Components/Leaders/Leaders";
import Award from "../../Components/Awards/Award";

export default function AboutPage() {
  return (
    <div>
      <HeaderBanner header="About Us" />
      <div className="about">
        <div className="about-left">
          <h6 className="small-header">ABOUT ONE WORLD LUXURY</h6>
          <h1 className="header">Explore Luxury At its Finest</h1>
          <p>
            Immerse yourself in the pinnacle of refined living at our exclusive
            luxury apartment, a testament to the seamless fusion of opulence and
            functionality. Crafted by an award-winning interior designer, each
            facet of this residence has been meticulously curated to provide an
            unparalleled living experience. The fully furnished space exudes
            sophistication, with bespoke furnishings and artistic elements that
            create an atmosphere of unmatched elegance.
          </p>
          <p>
            Step inside, and you'll discover a harmonious blend of contemporary
            design and timeless allure. The designer's keen eye for detail is
            evident in every corner, from the carefully selected color palettes
            to the thoughtfully placed lighting fixtures. Each room is a work of
            art, inviting you to indulge in the luxury of fine living. Our
            commitment to excellence goes beyond aesthetics; it extends to the
            functionality of the space, ensuring that every element enhances the
            overall living experience.
          </p>
          <p>
            Notably, our apartment has garnered recognition on the grand stage,
            securing two prestigious awards for its outstanding design. These
            accolades stand as a testament to the innovation and creativity
            embedded in every aspect of this residence. We take pride in
            providing our residents not just with a home but with an immersive
            lifestyle that has been celebrated and acknowledged by industry
            experts. Welcome to a world where luxury knows no bounds, and
            award-winning design becomes an everyday indulgence.
          </p>
        </div>
        <div className="about-right">
          <img src={AboutImg1} alt="aboutImg" />
          <img src={AboutImg2} alt="aboutImg" />
        </div>
      </div>
      {/* <Leaders /> */}
      <Award />
    </div>
  );
}
