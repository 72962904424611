import React, { useRef, useState } from "react";
import { BsFillPlayFill, BsPauseFill } from "react-icons/bs";
import introVid from "../../Media/ONE WORLD LUXURY.mp4";
import thumbnail from "../../Media/NOIR8.jpg";
import "./video.css";

export default function Video() {
  const [playVideo, setPlayVideo] = useState(false);
  const vidRef = useRef();

  const handleVideo = () => {
    setPlayVideo((prevPlayVideo) => !prevPlayVideo);

    if (playVideo) {
      vidRef.current.pause();
    } else {
      vidRef.current.play();
    }
  };

  return (
    <div className="video">
      <video
        poster={thumbnail}
        src={introVid}
        ref={vidRef}
        type="video/mp4"
        controls={false}
      />
      <div className="video-overlay">
        <div className="video-overlay_circle" onClick={handleVideo}>
          {playVideo ? (
            <BsPauseFill color="white" fontSize={30} />
          ) : (
            <BsFillPlayFill color="white" fontSize={30} />
          )}
        </div>
      </div>
    </div>
  );
}
