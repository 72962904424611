import React from "react";
import { useState } from "react";
import { SlCalender } from "react-icons/sl";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPerson } from "react-icons/fa6";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./availability.css";

export default function Availability() {
  const [email, setEmail] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      //startDate: "Sat Feb 10 2024 17:18:43 GMT+0000 (Greenwich Mean Time)",
      endDate: new Date(),
      //endDate: "Mon Feb 19 2024 17:18:43 GMT+0000 (Greenwich Mean Time)",
      key: "selection",
    },
  ]);
  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
  });

  var bookedDate = "Feb 19 2024 17:18:43 GMT+0000 (Greenwich Mean Time)";

  //console.log(new Date());

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/booking", { state: { email, date } });
  };

  return (
    <div className="availability">
      <div className="availability-search">
        <div className="search-item">
          <MdOutlineMailOutline />
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={email}
            className="search-input"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="search-item">
          <SlCalender />
          <span onClick={() => setOpenDate(!openDate)} className="search-text">
            {`${format(date[0].startDate, "dd/MM/yyyy")} to ${format(
              date[0].endDate,
              "dd/MM/yyyy"
            )}`}
            {/* {"20/02/2024 to 20/03/2024"} */}
          </span>
          {openDate && (
            <div className="date">
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDate([item.selection])}
                moveRangeOnFirstSelection={false}
                rangeColors={["#B48D52"]}
                ranges={date}
                minDate={new Date()}
                //minDate={new Date("02-29-2024")}
              />
              <button className="date-btn" onClick={() => setOpenDate(false)}>
                Done
              </button>
            </div>
          )}
        </div>
        <div className="search-item">
          <FaPerson />
          <span
            onClick={() => setOpenOptions(!openOptions)}
            className="search-text"
          >{`${options.adult} adult · ${options.children} children`}</span>
          {openOptions && (
            <div className="options">
              <div className="optionItem">
                <span className="optionText">Adult</span>
                <div className="optionCounter">
                  <button
                    disabled={options.adult <= 1}
                    className="optionCounterButton"
                    onClick={() => handleOption("adult", "d")}
                  >
                    -
                  </button>
                  <span className="optionCounterNumber">{options.adult}</span>
                  <button
                    className="optionCounterButton"
                    onClick={() => handleOption("adult", "i")}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="optionItem">
                <span className="optionText">Children</span>
                <div className="optionCounter">
                  <button
                    disabled={options.children <= 0}
                    className="optionCounterButton"
                    onClick={() => handleOption("children", "d")}
                  >
                    -
                  </button>
                  <span className="optionCounterNumber">
                    {options.children}
                  </span>
                  <button
                    className="optionCounterButton"
                    onClick={() => handleOption("children", "i")}
                  >
                    +
                  </button>
                </div>
              </div>
              {/* <div className="optionItem">
                <span className="optionText">Room</span>
                <div className="optionCounter">
                  <button
                    disabled={options.room <= 1}
                    className="optionCounterButton"
                    onClick={() => handleOption("room", "d")}
                  >
                    -
                  </button>
                  <span className="optionCounterNumber">{options.room}</span>
                  <button
                    className="optionCounterButton"
                    onClick={() => handleOption("room", "i")}
                  >
                    +
                  </button>
                </div>
              </div> */}
            </div>
          )}
        </div>
        <div className="search-item">
          <button className="search-button" onClick={handleSubmit}>
            Make Reservation
          </button>
        </div>
      </div>
    </div>
  );
}
