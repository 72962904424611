import React from "react";
import "./apartment.css";
import ApartImg1 from "../../Media/NOIR2.jpg";
import ApartImg2 from "../../Media/NOIR44.jpg";
import ApartImg3 from "../../Media/NOIR53.jpg";
import { Link } from "react-router-dom";

export default function Apartments() {
  return (
    <div className="apartments">
      <h6 className="small-header">ONE WORLD LUXURY ROOMS</h6>
      <h1 className="header">
        Explore Our <span style={{ color: "#B48D52" }}>ROOMS</span>
      </h1>
      <div className="apartments-boxes">
        <div className="apartments-box">
          <img src={ApartImg2} alt="Apartment" />
          <div className="box-content">
            <h3>Master Bedroom</h3>
            <p>
              The master bedroom is a testament to the uncompromising pursuit of
              comfort, style, and indulgence.
            </p>
            <Link to="/master">
              <button>VIEW MORE</button>
            </Link>
          </div>
        </div>

        <div className="apartments-box">
          <img src={ApartImg3} alt="Apartment" />
          <div className="box-content">
            <h3>Standard Bedroom</h3>
            <p>
              This bedroom offers an elegant retreat with a focus on both
              comfort and sophistication.
            </p>
            <Link to="/standard">
              <button>VIEW MORE</button>
            </Link>
          </div>
        </div>

        <div className="apartments-box">
          <img src={ApartImg1} alt="Apartment" />
          <div className="box-content">
            <h3>Junior Bedroom</h3>
            <p>
              The junior bedroom seamlessly connects to an en-suite bathroom,
              featuring contemporary fixtures and vanity.
            </p>
            <Link to="/junior">
              <button>VIEW MORE</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
