import React from "react";
import { MdConnectingAirports } from "react-icons/md";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { PiBuildingsLight } from "react-icons/pi";
import { IoRestaurantOutline } from "react-icons/io5";
import "./nearby.css";

export default function Nearby() {
  return (
    <div className="nearby">
      <h6 className="small-header">NEARBY LANDMARKS</h6>
      <h1 className="header">Landmarks Around Us</h1>
      <div className="nearby-container">
        <div className="nearby-place">
          <PiBuildingsLight />
          <h3>Foreign Affairs</h3>
          <p>50m from Us</p>
        </div>
        <div className="nearby-place">
          <MdConnectingAirports />
          <h3>Airport</h3>
          <p>1.2km from Us</p>
        </div>
        <div className="nearby-place">
          <AiOutlineShoppingCart />
          <h3>Marina Mall</h3>
          <p>411m from Us</p>
        </div>
        <div className="nearby-place">
          <IoRestaurantOutline />
          <h3>Restaurant</h3>
          <p>770m from Us</p>
        </div>
      </div>
    </div>
  );
}
