import React from "react";

export default function Map() {
  return (
    <div>
      <h6 style={{ textAlign: "center" }} className="small-header">
        MAP TO LOCATION
      </h6>
      <h1 style={{ textAlign: "center" }} className="header">
        Navigate Area around Palm Court
      </h1>
      <div style={{ height: "50vh" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.7508010619918!2d-0.1799208!3d5.6037826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9b028090af87%3A0xa0d393c3cd37af23!2sPalm%20Court%20Apartments%20Ghana!5e0!3m2!1sen!2sgh!4v1706389043100!5m2!1sen!2sgh"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
