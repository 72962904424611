import { Route, Routes, BrowserRouter } from "react-router-dom";
import { DetailsPages } from "./Components/details/DetailsPages";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import AboutPage from "./Pages/About Page/AboutPage";
import BlogPage from "./Pages/Blog Page/BlogPage";
import ContactPage from "./Pages/Contact Page/ContactPage";
import GalleryPage from "./Pages/Gallery Page/GalleryPage";
import HomePage from "./Pages/HomePage/HomePage";
import ScrollToTop from "./ScrollToTop";
import Master from "./Pages/Rooms Page/Master";
import Standard from "./Pages/Rooms Page/Standard";
import Junior from "./Pages/Rooms Page/Junior";
import Booking, { BookingPage } from "./Pages/Booking Page/Booking";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/about" exact element={<AboutPage />} />
        <Route path="/blogpage" exact element={<BlogPage />} />
        <Route path="/details/:id" exact element={<DetailsPages />} />
        <Route path="/gallerypage" exact element={<GalleryPage />} />
        <Route path="/contactpage" exact element={<ContactPage />} />
        <Route path="/master" exact element={<Master />} />
        <Route path="/standard" exact element={<Standard />} />
        <Route path="/junior" exact element={<Junior />} />
        <Route path="/booking" exact element={<Booking />} />
        <Route path="/bookingpage" exact element={<BookingPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
