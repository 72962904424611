import React, { useState } from "react";
import "./details.css";
import { BsPencilSquare } from "react-icons/bs";
import { AiOutlineClockCircle, AiOutlineDelete } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { blog } from "../../sliderData";

export const DetailsPages = () => {
  const { id } = useParams();
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    let blogs = blog.find((blogs) => blogs.id === parseInt(id));
    if (blogs) {
      setBlogs(blogs);
    }
  }, []);

  return (
    <>
      {blogs ? (
        <div className="blog-section">
          <img src={blogs.cover} alt="blog cover" />
          <div className="blog-info">
            <h3>{blogs.title}</h3>
            <div className="blogsection-date">
              <h6>
                <AiOutlineClockCircle color="black" />
                {"    "}
                {blogs.date}
              </h6>
              <h6>Written By: {blogs.author}</h6>
            </div>
            <p>{blogs.desc1}</p>
            <p>{blogs.desc2}</p>
            <p>{blogs.desc3}</p>
            <p>{blogs.desc4}</p>
            <p>{blogs.desc5}</p>
            <p>{blogs.desc6}</p>
          </div>
        </div>
      ) : null}
    </>
  );
};
