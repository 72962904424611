import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import "./navbar.css";
import Logo from "../../Media/One world luxury logo-01.png";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar">
      <div className="navbar-left">
        <Link to="/">
          <img src={Logo} className="logo" alt="Logo" />
        </Link>
      </div>
      <div className="navbar-links">
        <Link to="/gallerypage">Gallery</Link>
        <Link to="/about">About</Link>
        <Link to="/blogpage">Blog</Link>
        <Link to="/contactpage">Contacts</Link>
        <Link to="/bookingpage">
          <button className="nav-button">Book Now</button>
        </Link>
      </div>
      <div className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#063B6D"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#063B6D"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu-container scale-up-center">
            <Link to="/gallerypage">Gallery</Link>
            <Link to="/about">About</Link>
            <Link to="/blogpage">Blog</Link>
            <Link to="/contactpage">Contacts</Link>
            <Link to="/bookingpage">
              <button className="nav-button">Book Now</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
