import React, { useState } from "react";
import { motion } from "framer-motion";
import { imgMasters } from "../../sliderData";
import HeaderBanner from "../../Components/HeaderBanner/HeaderBanner";

export default function Master() {
  const [selectedImg, setSelectedImg] = useState(null);

  const handleClick = (e) => {
    if (e.target.classList.contains("backdrop")) {
      setSelectedImg(null);
    }
  };

  return (
    <>
      <HeaderBanner header="Master Bedroom" />
      <div className="gallerypage">
        <h6 className="small-header">Master Bedroom</h6>
        <h1 className="header">Explore The Master Bedroom</h1>
        <div className="img-grid">
          {imgMasters &&
            imgMasters.map((imgMaster) => (
              <motion.div
                className="img-wrap"
                key={imgMaster.id}
                layout
                whileHover={{ opacity: 1 }}
                onClick={() => setSelectedImg(imgMaster.url)}
              >
                <motion.img
                  src={imgMaster.url}
                  alt="uploaded pic"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1 }}
                />
              </motion.div>
            ))}
        </div>
        {selectedImg && (
          <motion.div
            className="backdrop"
            onClick={handleClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <motion.img
              src={selectedImg}
              alt="enlarged pic"
              initial={{ y: "-100vh" }}
              animate={{ y: 0 }}
            />
          </motion.div>
        )}
      </div>
    </>
  );
}
