import React from "react";
import "./headerbanner.css";

export default function HeaderBanner(props) {
  return (
    <div className="header-banner">
      <h1>{props.header}</h1>
    </div>
  );
}
