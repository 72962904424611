import React, { useState } from "react";
import "./testimonials.css";
import img1 from "../../Media/CN.png";
import img2 from "../../Media/DM.png";
import img3 from "../../Media/EW.png";
import img4 from "../../Media/GE.png";

const testimonials = {
  0: {
    img: img3,
    person: "Ebenezer Kwadjwo Williams",
    quote:
      "Paul made our month stay in this beautiful apartment exceptional. His attentiveness to our comfort was so refreshing. We absolutely loved the location of his apartment, the security at the gate, and the cleanliness of the apartment. Our family of four had more than enough space and were very comfortable. I am definitely staying here again when we go back.",
  },
  1: {
    img: img1,
    person: "Comfort Nartey",
    quote:
      "I cannot express enough gratitude for the incredible stay we had in Paul's luxurious apartment. The attention to luxury and comfort was unparalleled, from the plush furnishings to the state-of-the-art appliances. Paul's impeccable taste in decor created a sophisticated and inviting atmosphere, making us feel pampered throughout our entire stay. The central location and stunning cityscape views added to the overall charm of the experience. |Pual's thoughtful touches and personal recommendations made our visit unforgettable. We highly recommend this opulent haven for anyone seeking a truly indulgent getaway.",
  },
  2: {
    img: img4,
    person: "Geraldine Ebel",
    quote:
      "Staying in Paul's luxurious apartment was an absolute dream come true. The sleek and modern design, coupled with the breathtaking panoramic views, created a haven of opulence. Paul's thoughtfulness was evident in every detail, from the high-end amenities to the personalized welcome basket. The comfort of the plush bedding and the convenience of the well-equipped kitchen made our stay both indulgent and relaxing. Paul's prompt communication and genuine care for our experience added an extra layer of warmth to the already exceptional stay. We left feeling rejuvenated and grateful for the privilege of staying in such a remarkable space.",
  },
  3: {
    img: img2,
    person: "Dominic Magruder",
    quote:
      "Paul made our month stay in this beautiful apartment exceptional. His attentiveness to our comfort was so refreshing.  We absolutely loved the location of his apartment, the security at the gate, and the cleanliness of the apartment.  Our family of four had more than enough space and were very comfortable.  I am DEFINITELY staying here again when we go back.",
  },
};

const Testimonials = () => {
  const [current, setCurrent] = useState(testimonials[0]);
  const [active, setActive] = useState(0);

  const handleSetClick = (e) => {
    setCurrent(testimonials[e.target.getAttribute("data-quote")]);
    setActive(e.target.getAttribute("data-quote"));
  };

  return (
    <div className="testimonials" id="reviews">
      <h6 className="small-header">TESTIMONIALS</h6>
      <h1 className="header">
        Read Happy Customer <span style={{ color: "#B48D52" }}>REVIEWS</span>
      </h1>
      <div className="testimonial-profile">
        <img src={current.img} alt="testimonial-img" />
        <p>{current.person}</p>
      </div>
      <p className="quote">{current.quote}</p>
      <div className="toggles">
        {Object.keys(testimonials).map((index) => (
          <span
            onClick={(event) => handleSetClick(event)}
            data-quote={index}
            className={` ${active === index ? "active-toggle" : ""}`}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
