import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
import HeaderBanner from "../../Components/HeaderBanner/HeaderBanner";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import "./booking.css";

export function BookingPage() {
  const location = useLocation();
  const [name, setName] = useState("");
  const [elsename, setElseName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [whoOption, setWhoOption] = useState("I am the main guest");
  const [travellingOption, setTravellingOption] = useState("No");
  const [time, setTime] = useState("");
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [openDate, setOpenDate] = useState(false);

  var templateParams = {
    rangeDate: `${date[0].startDate} to ${date[0].endDate}`,
    user_email: email,
    user_name: name,
    else_name: elsename,
    user_number: number,
    user_message: message,
    user_who: whoOption,
    user_travelling: travellingOption,
    user_time: time,
  };

  const formSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_8cwagye",
        "template_chn6fkp",
        templateParams,
        "OkFjJiTsmKEzWQOB_"
      )
      .then(
        (result) => {
          alert("Your Booking was Submitted Successfully");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    console.log(templateParams);
    setDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setEmail(" ");
    setName("");
    setElseName("");
    setNumber("");
    setMessage("");
    setWhoOption("I am the main guest");
    setTravellingOption("No");
    setTime("");
  };

  return (
    <div>
      <HeaderBanner header="Book Us" />
      <div className="booking">
        <div className="booking-form">
          <h6 className="small-header">BOOKING FORM</h6>
          <h1 className="header">Enter Your Booking Details</h1>
          <form onSubmit={formSubmit}>
            <label>
              Full Name <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Your Full Name"
              className="input-text"
              required
            />
            <br />
            <label>
              Email Address <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email Address"
              className="input-text"
              required
            />
            <br />
            <label>
              Telephone Number <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="tel"
              name="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder="Enter Your Phone Number"
              className="input-text"
              required
            />
            <br />
            <label>
              Who are you booking for? <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <label>
              <input
                className="radio"
                type="radio"
                name="who"
                value="I am the main guest"
                checked={whoOption === "I am the main guest"}
                onChange={(e) => setWhoOption(e.target.value)}
              ></input>{" "}
              I am the main guest
            </label>
            <br />
            <label>
              <input
                style={{ marginBottom: "20px" }}
                className="radio"
                type="radio"
                name="who"
                value="Booking is for Someone Else"
                checked={whoOption === "Booking is for Someone Else"}
                onChange={(e) => setWhoOption(e.target.value)}
              ></input>{" "}
              Booking is for Someone Else
            </label>
            <br />
            <label>
              Name of the person you are booking for{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              name="elsename"
              value={elsename}
              onChange={(e) => setElseName(e.target.value)}
              placeholder="Enter his/her Full Name"
              className="input-text"
              required
            />
            <br />
            <label>
              Are you travelling for work?{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <label>
              <input
                className="radio"
                type="radio"
                name="travelling"
                value="Yes"
                checked={travellingOption === "Yes"}
                onChange={(e) => setTravellingOption(e.target.value)}
              ></input>{" "}
              Yes
            </label>
            <br />
            <label>
              <input
                className="radio"
                type="radio"
                name="travelling"
                value="No"
                checked={travellingOption === "No"}
                onChange={(e) => setTravellingOption(e.target.value)}
              ></input>{" "}
              No
            </label>
            <br />
            <h3>
              Special Requests{" "}
              <span style={{ color: "grey", fontSize: 12 }}>(optional)</span>
            </h3>
            <p style={{ marginBottom: 20 }}>
              The accommodation is unable to accommodate special requests
              preemptively. However, we are committed to diligently strive to
              fulfill your needs to the best of our ability. Should you have any
              specific requirements, we encourage you to communicate them after
              the booking process is finalized. Your satisfaction is of utmost
              importance, and we will make every effort to ensure a seamless and
              tailored experience for you.
            </p>
            <label>
              Please write your special requests if there's any.{" "}
              <span style={{ color: "grey", fontSize: 12 }}>(optional)</span>
            </label>
            <br />
            <textarea
              rows={4}
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="input-text"
            />
            <br />
            <h4>
              Check-in/ Check-out date <span style={{ color: "red" }}>*</span>
            </h4>
            <br />
            <div className="date-input">
              <span onClick={() => setOpenDate(!openDate)}>{`${format(
                date[0].startDate,
                "dd/MM/yyyy"
              )} to ${format(date[0].endDate, "dd/MM/yyyy")}`}</span>
            </div>
            {openDate && (
              <div className="date-range">
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setDate([item.selection])}
                  moveRangeOnFirstSelection={false}
                  rangeColors={["#B48D52"]}
                  ranges={date}
                  minDate={new Date()}
                />
                <button className="date-btn" onClick={() => setOpenDate(false)}>
                  Done
                </button>
              </div>
            )}
            <br />
            <h3>
              Your Arrival Time <span style={{ color: "red" }}>*</span>
            </h3>
            <label>
              Enter your estimated arrival time
              <span style={{ color: "grey", fontSize: 12 }}>
                (click on the clock icon)
              </span>
            </label>
            <br />
            <div className="date-input" style={{ marginTop: 15 }}>
              <input
                className="input-time"
                type="time"
                value={time}
                style={{ width: "150px", outline: "none" }}
                onChange={(e) => setTime(e.target.value)}
                required
              />
            </div>
            <h3>Review Apartment Rules</h3>
            <p>We would like you to acknowledge the following rules:</p>
            <ul>
              <li>No smoking</li>
              <li>No parties/events</li>
              <li>Pets are not allowed</li>
            </ul>
            <p>By continuing, you are agreeing to these rules.</p>
            <button className="book-btn" type="submit">
              SUBMIT BOOKING
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default function Booking() {
  const location = useLocation();
  const [name, setName] = useState("");
  const [elsename, setElseName] = useState("");
  const [email, setEmail] = useState(location.state.email);
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [whoOption, setWhoOption] = useState("I am the main guest");
  const [travellingOption, setTravellingOption] = useState("No");
  const [time, setTime] = useState("");
  const [date, setDate] = useState(location.state.date);
  const [openDate, setOpenDate] = useState(false);

  var templateParams = {
    rangeDate: `${date[0].startDate} to ${date[0].endDate}`,
    user_email: email,
    user_name: name,
    else_name: elsename,
    user_number: number,
    user_message: message,
    user_who: whoOption,
    user_travelling: travellingOption,
    user_time: time,
  };

  const formSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_8cwagye",
        "template_chn6fkp",
        templateParams,
        "OkFjJiTsmKEzWQOB_"
      )
      .then(
        (result) => {
          alert("Your Booking was Submitted Successfully");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    console.log(templateParams);
    setDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setEmail(" ");
    setName("");
    setElseName("");
    setNumber("");
    setMessage("");
    setWhoOption("I am the main guest");
    setTravellingOption("No");
    setTime("");
  };

  return (
    <div>
      <HeaderBanner header="Book Us" />
      <div className="booking">
        <div className="booking-form">
          <h6 className="small-header">BOOKING FORM</h6>
          <h1 className="header">Enter Your Booking Details</h1>
          <form onSubmit={formSubmit}>
            <label>
              Full Name <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Your Full Name"
              className="input-text"
              required
            />
            <br />
            <label>
              Email Address <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email Address"
              className="input-text"
              required
            />
            <br />
            <label>
              Telephone Number <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="tel"
              name="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder="Enter Your Phone Number"
              className="input-text"
              required
            />
            <br />
            <label>
              Who are you booking for? <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <label>
              <input
                className="radio"
                type="radio"
                name="who"
                value="I am the main guest"
                checked={whoOption === "I am the main guest"}
                onChange={(e) => setWhoOption(e.target.value)}
              ></input>{" "}
              I am the main guest
            </label>
            <br />
            <label>
              <input
                style={{ marginBottom: "20px" }}
                className="radio"
                type="radio"
                name="who"
                value="Booking is for Someone Else"
                checked={whoOption === "Booking is for Someone Else"}
                onChange={(e) => setWhoOption(e.target.value)}
              ></input>{" "}
              Booking is for Someone Else
            </label>
            <br />
            <label>
              Name of the person you are booking for{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <input
              type="text"
              name="elsename"
              value={elsename}
              onChange={(e) => setElseName(e.target.value)}
              placeholder="Enter his/her Full Name"
              className="input-text"
              required
            />
            <br />
            <label>
              Are you travelling for work?{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <br />
            <label>
              <input
                className="radio"
                type="radio"
                name="travelling"
                value="Yes"
                checked={travellingOption === "Yes"}
                onChange={(e) => setTravellingOption(e.target.value)}
              ></input>{" "}
              Yes
            </label>
            <br />
            <label>
              <input
                className="radio"
                type="radio"
                name="travelling"
                value="No"
                checked={travellingOption === "No"}
                onChange={(e) => setTravellingOption(e.target.value)}
              ></input>{" "}
              No
            </label>
            <br />
            <h3>
              Special Requests{" "}
              <span style={{ color: "grey", fontSize: 12 }}>(optional)</span>
            </h3>
            <p>
              The accommodation is unable to accommodate special requests
              preemptively. However, we are committed to diligently strive to
              fulfill your needs to the best of our ability. Should you have any
              specific requirements, we encourage you to communicate them after
              the booking process is finalized. Your satisfaction is of utmost
              importance, and we will make every effort to ensure a seamless and
              tailored experience for you.
            </p>
            <label>
              Please write your special requests if there's any.{" "}
              <span style={{ color: "grey", fontSize: 12 }}>(optional)</span>
            </label>
            <br />
            <textarea
              rows={4}
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="input-text"
            />
            <br />
            <h4>
              Check-in/ Check-out date <span style={{ color: "red" }}>*</span>
            </h4>
            <br />
            <div className="date-input">
              <span onClick={() => setOpenDate(!openDate)}>{`${format(
                date[0].startDate,
                "dd/MM/yyyy"
              )} to ${format(date[0].endDate, "dd/MM/yyyy")}`}</span>
            </div>
            {openDate && (
              <div className="date-range">
                <DateRange
                  onChange={(item) => setDate([item.selection])}
                  moveRangeOnFirstSelection={false}
                  rangeColors={["#B48D52"]}
                  ranges={date}
                  minDate={new Date()}
                />
                <button className="date-btn" onClick={() => setOpenDate(false)}>
                  Done
                </button>
              </div>
            )}
            <br />
            <h3>
              Your Arrival Time <span style={{ color: "red" }}>*</span>
            </h3>
            <label>
              Enter your estimated arrival time
              <span style={{ color: "grey", fontSize: 12 }}>
                (click on the clock icon)
              </span>
            </label>
            <br />
            <input
              className="input-text"
              type="time"
              style={{ width: "150px", outline: "none" }}
              value={time}
              required
              onChange={(e) => setTime(e.target.value)}
            />
            <h3>Review Apartment Rules</h3>
            <p>We would like you to acknowledge the following rules:</p>
            <ul>
              <li>No smoking</li>
              <li>No parties/events</li>
              <li>Pets are not allowed</li>
            </ul>
            <p>By continuing, you are agreeing to these rules.</p>
            <button className="book-btn" type="submit">
              SUBMIT BOOKING
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
