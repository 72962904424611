import React from "react";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaTiktok } from "react-icons/fa";
import footerLogo from "../../Media/One world luxury logo-03.png";
import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-left">
        <h3>Contact Us</h3>
        <div className="contact-link">
          <a href="https://maps.app.goo.gl/b9BVyCPpoJzisqpS8?g_st=iw">
            <MdLocationOn /> Palm Court, Airport Residential Area
          </a>
        </div>
        <div className="contact-link">
          <a href="tel:233531100850">
            <BsFillTelephoneFill /> +233 531 100 850
          </a>
        </div>
        <div className="contact-link">
          <a href="mailto:oneworldluxury1@gmail.com">
            <MdEmail /> oneworldluxury1@gmail.com
          </a>
        </div>
        <div className="contact-link-social">
          <a href="https://web.facebook.com/oneworldluxury">
            <FiFacebook />
          </a>
          <a href="https://www.instagram.com/oneworldluxurygh/?igsh=OGQ5ZDc2ODk2ZA%3D%3D">
            <FiInstagram />
          </a>
          <a href="https://www.tiktok.com/@one.world.luxury?_t=8iP8oGZy1sU&_r=1">
            <FaTiktok />
          </a>
        </div>
      </div>
      <div className="footer-center">
        <img src={footerLogo} height={80} alt="footer-logo" />
        <p>"Helping Everyone Find Their Place in the World"</p>
        <h5>&copy; 2023 OneWorld Luxury Brand</h5>
      </div>
      <div className="footer-right">
        <h3>Company</h3>
        <Link to="/about">
          <p>About Us</p>
        </Link>
        <Link to="/blogpage">
          <p>Blog</p>
        </Link>
        <Link to="/gallerypage">
          <p>Gallery</p>
        </Link>
        <Link to="/contactpage">
          <p>Contacts</p>
        </Link>
      </div>
    </div>
  );
}
