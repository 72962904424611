import React, { useState } from "react";
import { motion } from "framer-motion";
import { imgDocs } from "../../sliderData";
import "./gallerypage.css";
import HeaderBanner from "../../Components/HeaderBanner/HeaderBanner";
import IGgallery from "../../Components/IGgallery/IGgallery";

export default function GalleryPage() {
  const [selectedImg, setSelectedImg] = useState(null);

  const handleClick = (e) => {
    if (e.target.classList.contains("backdrop")) {
      setSelectedImg(null);
    }
  };

  return (
    <>
      <HeaderBanner header="Gallery" />
      <div className="gallerypage">
        <h6 className="small-header">GALLERY</h6>
        <h1 className="header">Explore Our Views</h1>
        <div className="img-grid">
          {imgDocs &&
            imgDocs.map((imgDoc) => (
              <motion.div
                className="img-wrap"
                key={imgDoc.id}
                layout
                whileHover={{ opacity: 1 }}
                onClick={() => setSelectedImg(imgDoc.url)}
              >
                <motion.img
                  src={imgDoc.url}
                  alt="uploaded pic"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1 }}
                />
              </motion.div>
            ))}
        </div>
        {selectedImg && (
          <motion.div
            className="backdrop"
            onClick={handleClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <motion.img
              src={selectedImg}
              alt="enlarged pic"
              initial={{ y: "-100vh" }}
              animate={{ y: 0 }}
            />
          </motion.div>
        )}
      </div>
      <IGgallery />
    </>
  );
}
