import React from "react";
import { PiCouchLight } from "react-icons/pi";
import { LuBadgeCheck } from "react-icons/lu";
import { LiaHandshake } from "react-icons/lia";
import { LiaThumbsUp } from "react-icons/lia";
import "./whyus.css";

export default function WhyUs() {
  return (
    <div className="whyus">
      <h6 className="small-header">WHY CHOOSE US</h6>
      <h1 className="header">
        Why Choose <span style={{ color: "#B48D52" }}>ONE WORLD</span>
      </h1>
      <div className="whyus-boxes">
        <div className="whyus-box">
          <PiCouchLight />
          <h3>Comfort</h3>
          <p>
            Indulge in the unparalleled comfort of this luxurious apartment.
          </p>
        </div>
        <div className="whyus-box">
          <LiaThumbsUp />
          <h3>Quality</h3>
          <p>
            Experience the epitome of refined living with the exceptional
            quality of this luxurious apartment.
          </p>
        </div>
        <div className="whyus-box">
          <LuBadgeCheck />
          <h3>Assurance</h3>
          <p>
            State-of-the-art security features and concierge services provide
            unparalleled assurance and peace of mind.
          </p>
        </div>
        <div className="whyus-box">
          <LiaHandshake />
          <h3>Trust</h3>
          <p>
          Instilling unwavering trust in every aspect of your refined living experience.
          </p>
        </div>
      </div>
    </div>
  );
}
