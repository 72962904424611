import React from "react";
import "./about.css";
import AboutImg1 from "../../Media/NOIR4.jpg";
import AboutImg2 from "../../Media/NOIR41.jpg";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="about">
      <div className="about-left">
        <h6 className="small-header">ABOUT ONE WORLD LUXURY</h6>
        <h1 className="header">Explore Luxury At its Finest</h1>
        <p>
        Immerse yourself in the pinnacle of refined living at our exclusive luxury apartment, a testament to the seamless fusion of opulence and functionality. Crafted by an award-winning interior designer, each facet of this residence has been meticulously curated to provide an unparalleled living experience. The fully furnished space exudes sophistication, with bespoke furnishings and artistic elements that create an atmosphere of unmatched elegance.
        </p>
        <Link to="/about">
        <button>KNOW MORE</button>
        </Link>
      </div>
      <div className="about-right">
        <img src={AboutImg1} alt="aboutImg" />
        <img src={AboutImg2} alt="aboutImg" />
      </div>
    </div>
  );
}
